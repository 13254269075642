import { useTranslation } from "react-i18next";
// inner components
import Hero from "./Sections/Hero";
import ServicesLanding from "./Sections/ServicesLanding";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Services = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("services");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const title = data?.servicedata[i18n.language]?.title;
    const text = data?.servicedata[i18n.language]?.text;
    const landingData = data?.servicedata[i18n.language]?.servicesData;

    return (
      <main className="mb-secondary lg:mb-primary">
        <Hero title={title} text={text} />
        <ServicesLanding data={landingData} />
      </main>
    );
  }
};

export default Services;
