import { useTranslation } from "react-i18next";
import Container from "Components/Container/Container";
import image from "Asset/partners/partners-topbanner.jpg";
import imageAr from "Asset/partners/partners-ar.jpg";

const Hero = () => {
  const { t, i18n } = useTranslation();

  const heroData = {
    en: {
      title: "our Partners",
      description: `our Technology <br /> Partners`,
      image: image,
    },
    ar: {
      title: "الشركاء",
      description: "شركائنا التقنيين",
      image: imageAr,
    },
  };

  return (
    <section
      className="h-[40vh] md:h-[60vh] background-class"
      style={{
        backgroundImage: `url(${heroData[i18n.language].image})`,
      }}
    >
      <Container className={"h-full"}>
        <div className={"h-full flex flex-col justify-center"}>
          <h2
            className={`text-green ${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            }  uppercase text-sm font-[500]`}
          >
            {t("description", {
              defaultValue: heroData[i18n.language].title,
            })}
          </h2>

          <p
            className={`text-white ${
              i18n.language === "ar" ? "font-almarai" : "font-orbi"
            }   font-[600] text-3xl md:text-4xl capitalize`}
            dangerouslySetInnerHTML={{
              __html: t("description", {
                defaultValue: heroData[i18n.language].description,
              }),
            }}
          />
        </div>
      </Container>
    </section>
  );
};

export default Hero;
