const footerContent = {
  en: {
    links: [
      {
        title: "About Us",
        url: "/about",
      },
      {
        title: "Partners",
        url: "/partners",
      },
    ],

    services: {
      title: "Services",

      links: [
        {
          title: "Information And Communication",
          items: [
            "Technology Systems",
            "Information And Communication",
            "Technology Systems",
          ],
        },
        {
          title: "Information And Communication",
          items: [
            "Technology Systems",
            "Information And Communication",
            "Technology Systems",
          ],
        },
        {
          title: "Information And Communication",
          items: [
            "Technology Systems",
            "Information And Communication",
            "Technology Systems",
          ],
        },
      ],
    },

    contactInfo: {
      address:
        "123 Main Street, Business District, Dubai, United Arab Emirates",
      mobile: {
        label: "Mob:",
        number: "+971 50 123 4567",
      },
      email: "info@dubaisolutions.com",
      socialMedia: "KEEP IN TOUCH      ",
    },
  },

  ar: {
    links: [
      {
        title: "من نحن",

        url: "/about",
      },
      {
        title: "الشركاء",
        url: "/partners",
      },
    ],

    services: {
      title: "الخدمات",

      links: [
        {
          title: "المعلومات والاتصالات",
          items: [
            "أنظمة التكنولوجيا",
            "المعلومات والاتصال",
            "أنظمة التكنولوجيا",
          ],
        },
        {
          title: "المعلومات والاتصال",
          items: [
            "أنظمة التكنولوجيا",
            "المعلومات والاتصال",
            "أنظمة التكنولوجيا",
          ],
        },
        {
          title: "المعلومات والاتصال",
          items: [
            "أنظمة التكنولوجيا",
            "المعلومات والاتصال",
            "أنظمة التكنولوجيا",
          ],
        },
      ],
    },

    contactInfo: {
      address: "123 شارع ماين، الحي التجاري، دبي، الإمارات العربية المتحدة",
      mobile: {
        label: "جوال:",
        number: "+971 50 123 4567",
      },
      email: "info@dubaisolutions.com",
      socialMedia: "ابق على تواصل      ",
    },
  },
};

export default footerContent;
