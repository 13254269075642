import Container from "Components/Container/Container";
import { useTranslation } from "react-i18next";

import LandingVendor from "./Vendors/LandingVendor";
import MobileVendors from "./Vendors/MobileVendors";
const PartnersLanding = ({ data }) => {
  const colors = ["#8a68ad", "#a7c951", "#26b8ef"];
  const { i18n } = useTranslation();

  console.log(data);
  return (
    <section className="">
      {data?.map(({ title, list }, index) => (
        <div
          className={`odd:bg-gray-300 py-secondary ${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          }`}
          key={index}
        >
          <Container>
            <div className="mb-10 border-b pb-4 border-gray-400">
              <h1
                style={{ color: `${colors[index % colors.length]}` }}
                className={`md:w-1/2 text-2xl md:text-3xl  capitalize font-[500] `}
              >
                {title}
              </h1>
            </div>

            {list.map(
              ({ title, vendors }, innerIndex) =>
                vendors.length > 0 && (
                  <div key={innerIndex} className="mb-14">
                    <h2 className="text-xl mb-3 capitalize font-[500]">
                      {title}
                    </h2>

                    <LandingVendor vendors={vendors} />
                    <MobileVendors vendors={vendors} />
                  </div>
                )
            )}
          </Container>
        </div>
      ))}
    </section>
  );
};

export default PartnersLanding;
