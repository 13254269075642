import React, { createContext, useState, useCallback } from "react";
// import getData from "Helpers/GetContent";
import useFetch from "Hooks/useFetch";
export const AboutContext = createContext();

export const AboutProvider = ({ children }) => {
  const { data, loading, error } = useFetch("about_us");

  return (
    <AboutContext.Provider value={{ data, loading, error }}>
      {children}
    </AboutContext.Provider>
  );
};
