import useFetch from "Hooks/useFetch";
import { useTranslation } from "react-i18next";

const HeaderData = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("services");

  const serviceData = data?.servicedata[i18n.language].servicesData;

  return {
    en: [
      {
        text: "About us",
        link: "/about",
      },
      {
        text: "Our services",
        link: "/services",
        mega: true,
        serviceData: serviceData?.map(({ title, slug }, index) => ({
          title: title,
          slug: `/services/${slug}`,
        })),
      },
      {
        text: "Partners",
        link: "/partners",
      },
      {
        text: "Contact us",
        link: "/contact-us",
      },
    ],

    ar: [
      {
        text: "من نحن",
        link: "/about",
      },
      {
        text: "خدماتنا",
        link: "/services",
        mega: true,
        serviceData: serviceData?.map(({ title, slug }, index) => ({
          title: title,
          slug: `/services/${slug}`,
        })),
      },
      {
        text: "الشركاء",
        link: "/partners",
      },
      {
        text: "اتصل بنا",
        link: "/contact-us",
      },
    ],
  };
};

export default HeaderData;
