import Container from "Components/Container/Container";
import buttonContent from "Constant/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const ServicesLanding = ({ data }) => {
  const colors = ["#26b8ef", "#8a68ad", "#a7c951"];
  const { i18n } = useTranslation();

  console.log(data);
  return data?.map((item, index) => (
    <section
      className="py-secondary lg:py-primary"
      style={{ backgroundColor: `${colors[index % colors.length]}` }}
    >
      <Container>
        <div
          key={index}
          className="background-class flex flex-col justify-center lg:justify-start rounded-2xl px-4 py-0 md:px-10 md:py-20 text-white py-20"
          style={{ backgroundImage: `url(${item.image})` }}
        >
          <div
            className={`${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            }`}
          >
            <h1 className="capitalize font-extrabold text-2xl md:text-3xl md:w-3/4 lg:w-1/2 ">
              {item.title}
            </h1>
            <ul className="grid grid-cols-1 sm:grid-cols-2 grid-rows-3 gap-3 list-disc list-inside  capitalize text-lg font-[500] my-6 lg:my-8 services-list lg:w-[63%] xxl:w-1/2  ">
              {item?.list?.map(({ title }, index) => (
                <li key={index}>{title}</li>
              ))}
            </ul>
          </div>

          <div>
            <Link
              to={`/services/${item.slug}`}
              className="bg-green px-4 py-3 uppercase text-sm font-roboto font-[500] rounded-2xl"
            >
              {buttonContent[i18n.language].readMore}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  ));
};

export default ServicesLanding;
