const buttonContent = {
  en: {
    learnMore: "Learn More",
    readMore: "Read More",
  },
  ar: {
    learnMore: "اعرف المزيد",
    readMore: "اقرأ المزيد",
  },
};

export default buttonContent;
