import Container from "../../Components/Container/Container";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const NotFound = () => {
  const { i18n } = useTranslation();
  const data = {
    en: {
      title: "404 - Not Found",
      description:
        "Oops! The page you are looking for might be in another castle.",
      home: "Home",
      text: "let's get you back on track: ",
    },
    ar: {
      title: "404 - لم يتم العثور",
      description: "عفوًا! قد يكون الصفحة التي تبحث عنها في قلعة أخرى.",
      home: "الصفحة الرئيسية",
      text: "دعنا نعيدك إلى المسار الصحيح: ",
    },
  };
  return (
    <section className="h-[100vh] bg-purple">
      <Container className="h-full">
        <div className="h-full flex flex-col justify-center">
          <h1 className="font-orbi text-3xl text-white">
            {data[i18n.language].title}
          </h1>
          <p className="font-roboto text-2xl text-white mb-2 mt-6">
            {data[i18n.language].description}
          </p>

          <p className="font-roboto text-2xl text-white">
            {data[i18n.language].text}
            <Link to={"/"} className="text-blue underline">
              {data[i18n.language].home}
            </Link>
          </p>
        </div>
      </Container>
    </section>
  );
};

export default NotFound;
