import React from "react";
import { useTranslation } from "react-i18next";
import Container from "Components/Container/Container";
import { Link } from "react-router-dom";
import buttonContent from "Constant/Button";
import fixImageUrl from "Helpers/FixImageUrl";
import htmlToText from "Helpers/htmlToText";
const Hero = ({ data }) => {
  const { t, i18n } = useTranslation();
  const imageUrl = fixImageUrl(data?.image);
  return (
    <section
      className={`h-[100vh] background-class `}
      style={{
        backgroundImage: data && data?.image ? `url(${imageUrl})` : "",
      }}
    >
      <Container className="h-full">
        <div className="h-full flex flex-col justify-center md:w-1/2">
          <p
            className={`${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            } font-light uppercase text-white text-xl md:text-2xl mb-2`}
          >
            {htmlToText(data?.subtitle)}
          </p>
          <h1
            className={`${
              i18n.language === "ar" ? "font-almarai" : "font-orbi"
            }   text-white text-5xl md:text-6xl font-bold`}
          >
            {htmlToText(data?.title)}
          </h1>

          <div className="flex mt-10">
            <Link
              to={"/about"}
              className={`bg-green px-6 py-2 rounded-2xl text-white ${
                i18n.language === "ar" ? "font-almarai" : "font-roboto"
              }  text-lg`}
            >
              {buttonContent[i18n.language].learnMore}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
