import Container from "Components/Container/Container";
import { useTranslation } from "react-i18next";

const DetailedServicesHero = ({ data }) => {
  const { i18n } = useTranslation();

  return (
    <section className="bg-blue py-secondary lg:py-primary">
      <Container className={"h-full"}>
        <div className="flex h-full flex-col justify-center">
          <div
            className={`h-[300px] md:h-[400px]  mt-16 p-8 background-class flex flex-col justify-center rounded-2xl ${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            }`}
            style={{
              backgroundImage: `url(${data.image})`,
            }}
          >
            <p className="text-green mb-2">{`${
              i18n.language === "ar" ? "خدماتنا" : "Our Services"
            }`}</p>
            <h1 className="text-3xl md:text-4xl text-white ss:w-3/4 sm:w-1/2 font-[500]">
              {data.title}
            </h1>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DetailedServicesHero;
