import { useTranslation } from "react-i18next";
// inner components
import Hero from "./Sections/hero/Hero";
import PartnersLanding from "./Sections/Landing/PartnersLanding";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Partners = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("partners");

  const partner = data?.PartnerData[i18n.language];

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    return (
      <main>
        <Hero />
        <PartnersLanding data={partner} />
      </main>
    );
  }
};

export default Partners;
