import Container from "Components/Container/Container";
import { useTranslation } from "react-i18next";
import htmlToText from "Helpers/htmlToText";

const Hero = ({ title, text }) => {
  const { t, i18n } = useTranslation();

  return (
    <section className="bg-blue text-white pt-40 lg:pt-64">
      <Container className="h-full">
        <div className=" h-full w-full lg:w-[90%] flex flex-col justify-center">
          <h2
            className={`mb-3 ${
              i18n.language === "ar" ? "font-almarai" : "font-orbi"
            }  text-4xl capitalize font-[500]`}
          >
            {title}
          </h2>
          <p
            className={`mt-2 ${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            } leading-7 text-lg`}
          >
            {htmlToText(text)}
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
