import Container from "Components/Container/Container";
import React from "react";
import borderImage from "Asset/f1.png";
import { useTranslation } from "react-i18next";

import footerContent from "Constant/Footer";

// cols
import LinksCol from "./Columns/LinksCol";
import ServicesCol from "./Columns/ServicesCol";
import ContactCol from "./Columns/ContactCol";

const Footer = () => {
  const { i18n } = useTranslation();
  const titleStyle = `text-blue ${
    i18n.language === "ar" ? "font-almarai" : "font-roboto"
  } uppercase`;

  return (
    <footer className="">
      <div>
        <img className="w-full" src={borderImage} alt="" />
      </div>
      <Container>
        <div
          className={`grid grid-cols-1 md:grid-cols-4 gap-10 lg:grid-cols-5 py-secondary lg:py-primary ${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          }`}
        >
          <LinksCol
            style={titleStyle}
            links={footerContent[i18n.language]?.links}
          />

          <ServicesCol style={titleStyle} />

          <ContactCol style={titleStyle} />
        </div>
      </Container>
      <div className="bg-gray-200 py-innner">
        <p className="text-center">
          © All rights reserved. Powered by{" "}
          <a
            target="_blank"
            without
            className="underline text-blue"
            rel="noreferrer"
            href="https://www.brandraw.sa/"
          >
            Brandraw
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
