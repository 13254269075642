import { useTranslation } from "react-i18next";
// inner components
import Hero from "./Sections/Hero/Hero";
import Services from "./Sections/Services/Services";
import About from "./Sections/About/About";
import Partner from "./Sections/Partners/Partner";

// fetch data
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Home = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("home");
  const homeData = data?.homeData.home;

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const hero = homeData[i18n.language]?.hero[0];
    const servicesData = homeData[i18n.language]?.servicesData;
    const about = homeData[i18n.language]?.AboutUs;
    const partners = homeData[i18n.language]?.ourPartnersLogo;

    return (
      <main>
        <Hero data={hero} />
        <Services data={servicesData} />
        <About data={about} />
        <Partner data={partners} />
      </main>
    );
  }

  return null;
};

export default Home;
