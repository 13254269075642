// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./MobileVendor.css";

// import required modules
import { Pagination } from "swiper/modules";
const MobileVendors = ({ vendors }) => {
  const breakpoints = {
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  };
  return (
    <div className="md:hidden">
      <Swiper
        // pagination={true}
        pagination={{
          dynamicBullets: true,
        }}
        slidesPerView={2}
        spaceBetween={10}
        modules={[Pagination]}
        breakpoints={breakpoints}
        className="partners-logo-slider"
      >
        {vendors.map(({ image, title }, vnIndex) => (
          <SwiperSlide key={vnIndex}>
            <div className="flex flex-col items-center justify-center ">
              <div className="border border-gray-500  rounded-2xl flex items-center center justify-center overflow-hidden">
                <img
                  src={image}
                  alt=""
                  className=" rounded-2xl w-32 h-20 md:w-40 md:h-22"
                />
              </div>
              <p className="font-roboto text-sm"> {title}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MobileVendors;
