import Container from "Components/Container/Container";
import { useTranslation } from "react-i18next";
import htmlToText from "Helpers/htmlToText";

const DeatiledServicesBoxes = ({ servicesData }) => {
  const { i18n } = useTranslation();

  return (
    <section>
      <div className="flex flex-col gap-y-6">
        {servicesData?.map(({ title, image, description, vendors }, index) => (
          <div key={index} className="even:bg-gray-300">
            <Container>
              <div className="grid grid-col-1 xl:grid-cols-3 items-stretch gap-6 py-10">
                <div className="xl:col-span-1 flex">
                  <img
                    src={image}
                    alt=""
                    className="object-cover xl:max-h-[250px]"
                  />
                </div>
                <div
                  className={`xl:col-span-2 flex flex-col gap-y-4 ${
                    i18n.language === "ar" ? "font-almarai" : "font-roboto"
                  }`}
                >
                  <div>
                    <h4 className=" font-[700] text-blue text-2xl uppercase">
                      {title}
                    </h4>
                    <p className=" leading-6">{htmlToText(description)}</p>
                  </div>

                  {vendors && vendors.length > 0 && (
                    <div className="flex flex-col ">
                      <p className="text-xs font-bold uppercase text-gray-500">
                        {`${
                          i18n.language === "ar" ? "البائعون" : "Our vendors"
                        }`}
                      </p>
                      <div className="flex items-center flex-wrap gap-4 mt-2">
                        {vendors.map(({ image }, index) => (
                          <div className="" key={index}>
                            <div className="border border-gray-500 w-28 h-16 p-3 rounded-2xl flex items-center center justify-center overflow-hidden bg-white ">
                              <img
                                src={image}
                                alt=""
                                className="rounded-2xl object-cover"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DeatiledServicesBoxes;
