import nwcLogo from "Asset/Header/NWC-logo.png";
import taminiLogo from "Asset/Header/Tamini-logo.png";
import stikcyLogo from "Asset/Header/NWC-logo-b.png";
import { Link } from "react-router-dom";
const Logo = ({ isScroll }) => {
  return (
    <div className="flex items-center gap-x-2 ">
      <div
        className={`${
          isScroll ? "w-32 h-14 lg:w-40 lg:h-20" : "w-40 h-20 lg:w-60 lg:h-32"
        } `}
      >
        <Link to={"/"}>
          <img
            className="w-full h-full object-contain"
            // src={nwcLogo}
            src={isScroll ? stikcyLogo : nwcLogo}
            alt="NWC Logo"
          />
        </Link>
      </div>
      <div
        className={`${
          isScroll ? "w-10 h-10 lg:w-16 lg:h-16" : "w-16 h-16 lg:w-24 lg:h-24"
        }`}
      >
        <img
          className="w-full h-full object-contain"
          src={taminiLogo}
          alt="Tamini Logo"
        />
      </div>
    </div>
  );
};

export default Logo;
