import Container from "Components/Container/Container";
import { t } from "i18next";
import { Link } from "react-router-dom";
import buttonContent from "Constant/Button";
import { useTranslation } from "react-i18next";
import fixImageUrl from "Helpers/FixImageUrl";
import htmlToText from "Helpers/htmlToText";

const About = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <section
      className="background-class py-secondary lg:py-primary"
      style={{
        backgroundImage: data && data?.image ? `url(${data.image})` : "",
      }}
    >
      <Container className="h-full">
        <div
          className={`h-full flex flex-col justify-center ${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          }  lg:w-1/2`}
        >
          <h4 className="text-xl capitalize font-[900]">
            {t("title", {
              defaultValue: data?.title,
            })}
          </h4>

          <h1 className="text-blue text-lg leading-7 tracking-wide font-[900] mt-1 mb-2">
            {/* {t("subtitle", {
              defaultValue: data?.subtitle,
            })} */}

            {htmlToText(data?.text)}
          </h1>
          {/* 
          <p className=" font-[500]">
            {t("text", {
              defaultValue: data?.text,
            })}
          </p> */}

          <div className="flex mt-6">
            <Link
              to={"/about"}
              className="bg-blue text-white uppercase px-6 py-2 rounded-[22px]"
            >
              {buttonContent[i18n.language].readMore}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
