const inputLabels = {
  en: {
    title: "Get In touch ",
    Full_Name: "Full Name *",
    First_Name: "First Name *",
    Last_Name: "Last Name *",
    Phone_Number: "Mobile Number *",
    Company: "Company name *",
    Work_Email: "Work Email *",
    Message: "Your Message *",
    Submit: "Submit",

    formIsNotValid: "Please make sure all required fields are filled",
    formIsSuccess: "Form submitted successfully ",
    formIsFailed: "Failed to submit form, please try again later",
  },
  ar: {
    title: "ابق على اتصال",
    Full_Name: "الاسم الكامل*",
    First_Name: "الاسم الأول",
    Last_Name: "الاسم الأخير",
    Phone_Number: "رقم الجوال *",
    Company: "اسم الشركة*",
    Work_Email: "البريد الإلكتروني للعمل*",
    Message: "رسالتك*",
    Submit: "إرسال",
    formIsNotValid: " يرجى التأكد من ملء جميع الحقول المطلوبة ",
    formIsSuccess: "تم إرسال النموذج بنجاح",
    formIsFailed: "حدث خطأ أثناء إرسال النموذج، يرجى المحاولة مرة أخرى",
  },
};

export default inputLabels;
