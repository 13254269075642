// LanguageSwitcher.js
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowFatLeft, ArrowFatRight } from "@phosphor-icons/react";

const LanguageSwitcher = ({ isScroll, isMobile }) => {
  const { i18n } = useTranslation();
  const [languageIsVisible, setLanguageIsVisible] = useState(false);

  const handleToggleLanguage = () => {
    setLanguageIsVisible(!languageIsVisible);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);

    setTimeout(() => {
      setLanguageIsVisible(false);
    }, 100);
  };

  return (
    <div className="relative">
      <button
        onClick={handleToggleLanguage}
        className={` uppercase font-[500] tracking-wider  transition ease-in duration-300 border ${
          isScroll
            ? "text-[#333] border-[#333]"
            : "text-white hover:text-[#ddd]  border-white"
        }  m-0 py-2 px-4  rounded-md ${
          i18n.language === "ar"
            ? "mt-1.5 text-base font-almarai"
            : "text-sm font-roboto"
        }`}
      >
        {i18n.language === "ar" ? "اللغة" : " Language"}
      </button>

      {languageIsVisible && (
        <div
          className={`absolute  left-0 flex flex-col bg-purple py-4 px-6 max-w-[20rem] list-disc list-inside flex flex-col gap-y-5 rounded-md  -bottom-[4rem]  ${
            isMobile ? "bg-white text-black" : "text-white"
          } ${
            i18n.language === "ar" && isMobile
              ? "right-0 w-max"
              : "left-0 w-max"
          }`}
        >
          {i18n.language === "en" && (
            <button
              className="hover:underline flex items-center gap-x-3"
              onClick={() => handleLanguageChange("ar")}
            >
              <p className="font-almarai"> عربي</p>
              <span className="animate-pulse mt-2">
                <ArrowFatRight size={16} />
              </span>
            </button>
          )}

          {i18n.language === "ar" && (
            <button
              className="hover:underline flex items-center gap-x-3"
              onClick={() => handleLanguageChange("en")}
            >
              <p className="font-roboto"> English</p>
              <span className="animate-pulse">
                <ArrowFatLeft size={16} />
              </span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
