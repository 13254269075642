import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Slider.css";
import { Pagination, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import fixImageUrl from "Helpers/FixImageUrl";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

const PartnersSlider = ({ data }) => {
  const [swiperKey, setSwiperKey] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [i18n.language]);
  const CustomPrevButton = () => (
    <button className="custom-prev-button bg-purple p-1 rounded-full">
      {i18n.language === "ar" ? (
        <CaretRight color="#a7c951" size={24} weight="fill" />
      ) : (
        <CaretLeft color="#a7c951" size={24} weight="fill" />
      )}
    </button>
  );

  const CustomNextButton = () => (
    <button className="custom-next-button bg-purple p-1 rounded-full">
      {i18n.language === "ar" ? (
        <CaretLeft color="#a7c951" size={24} weight="fill" />
      ) : (
        <CaretRight color="#a7c951" size={24} weight="fill" />
      )}
    </button>
  );

  return (
    <Swiper
      key={swiperKey}
      spaceBetween={0}
      pagination={{
        el: ".my-dots-container",
        clickable: true,
        type: "bullets",
      }}
      navigation={{
        prevEl: ".custom-prev-button",
        nextEl: ".custom-next-button",
      }}
      slidesPerView={2}
      slidesPerGroup={2}
      breakpoints={{
        578: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        768: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        992: {
          slidesPerView: 6,
          slidesPerGroup: 6,
        },
      }}
      modules={[Pagination, Navigation]}
      className="partnerSwipper"
      speed={800}
    >
      {data?.map(({ image }, index) => (
        <SwiperSlide
          key={index}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="w-36 h-24 md:w-28 md:h-28 flex items-center justify-center p-2 md:p-1 lg:p-0">
            <img
              src={`${fixImageUrl(image)}`}
              alt=""
              className="h-full w-full object-contain"
            />
          </div>
        </SwiperSlide>
      ))}

      <div className="w-full flex items-center justify-between  gap-x-6 mt-10">
        <div className="flex items-center gap-x-6 w-full">
          <div className="w-full h-[2px] bg-purple "></div>
          <div className="my-dots-container max-w-fit min-w-fit"></div>
        </div>

        <div className="  gap-x-2 hidden lg:flex">
          <CustomPrevButton />
          <CustomNextButton />
        </div>
      </div>
    </Swiper>
  );
};

export default PartnersSlider;
