import { Route, Routes } from "react-router-dom";
import ContextProvider from "Context/ContextProvider";
// layout
import Header from "Layout/Header/Header";
import Footer from "Layout/Footer/Footer";
// pages
import Home from "Pages/Home/Home";
import About from "Pages/About/About";
import Partner from "Pages/Partners/Partners";
import Services from "Pages/Services/Services";
import DetailedServices from "Pages/Services/DetailedServices/DetailedServices";
import ContactUs from "Pages/Contact/ContactUs";

import NotFound from "Pages/NotFound/NotFound";
import ScrollToTop from "Helpers/ScrollToTop";
const App = () => {
  return (
    <div className="App">
      <ContextProvider />
      <ScrollToTop />
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/partners" element={<Partner />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:slug" element={<DetailedServices />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
      <ContextProvider />
    </div>
  );
};

export default App;
