import { useState } from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import HeaderData from "Constant/Header";
import { Link, useLocation } from "react-router-dom";

const Navigation = ({ isScroll }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const match = location.pathname;

  const [activeIndex, setActiveIndex] = useState(null);

  const openMegaMenuHandler = (index) => {
    setActiveIndex(index);
  };

  const closeMegaMenuHandler = () => {
    setActiveIndex(null);
  };

  const links = HeaderData();

  return (
    <div
      className="flex items-center gap-x-2 "
      onMouseLeave={closeMegaMenuHandler}
    >
      <ul className="flex items-center gap-x-6">
        {links?.[i18n.language]?.map(
          ({ link, text, mega, serviceData }, index) => (
            <li key={index} onMouseEnter={() => openMegaMenuHandler(index)}>
              <Link
                to={link}
                className={`${
                  i18n.language === "ar" ? "font-almarai" : "font-roboto"
                } uppercase  py-6  font-[500] tracking-wider text-sm transition ease-in duration-300  ${
                  isScroll ? "text-[#333]" : `text-white hover:text-[#ddd] `
                } ${match.includes(link) ? "text-[#a7c951] underline" : ""}`}
              >
                {text}
              </Link>

              {mega && activeIndex === index && (
                <ul className="absolute flex flex-col bg-purple px-8 py-6 mt-4 max-w-[20rem] list-disc list- flex flex-col gap-y-5 rounded-md text-white">
                  {serviceData?.map((item, subIndex) => (
                    <li key={subIndex}>
                      <Link
                        to={item.slug}
                        className={`capitalize text-white ${
                          i18n.language === "ar"
                            ? "font-almarai"
                            : "font-roboto"
                        } transition ease-in duration-300 hover:underline"`}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )
        )}
      </ul>
      <LanguageSwitcher isScroll={isScroll} />
    </div>
  );
};

export default Navigation;
