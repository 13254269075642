import Container from "Components/Container/Container";
import { useTranslation } from "react-i18next";
import React from "react";
import htmlToText from "Helpers/htmlToText";

const ParentCompany = ({ data }) => {
  const { t, i18n } = useTranslation();

  const paragraphs = data?.text?.split("</p>");

  return (
    <section
      className="background-class  py-innner lg:py-primary "
      style={{
        backgroundImage: data && data?.image ? `url(${data.image})` : "",
      }}
    >
      <Container className={"h-full"}>
        <div className="flex flex-col lg:grid lg:grid-cols-4 lg:items-center  justify-center h-full gap-x-14">
          <div
            className={`flex-1 lg:col-span-3 ${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            }`}
          >
            <h2 className="text-4xl font-[400] text-gray-400">
              {t("description", {
                defaultValue: data.title,
              })}
            </h2>
            <p className="text-3xl text-white mb-6">
              {t("title", { defaultValue: data.subTitle })}
            </p>

            {paragraphs.map((paragraph, index) => (
              <p key={index} className="text-white leading-7 text-sm mb-2">
                {htmlToText(paragraph)}
              </p>
            ))}
          </div>

          <div className="lg:col-span-1 flex-1 flex  lg:items-center lg:justify-center">
            <img
              className="w-52 h-52 lg:w-64 lg:h-64 object-contain"
              src={data.logo}
              alt=""
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ParentCompany;
