import Container from "Components/Container/Container";
import Input from "form/Inputs/Input";
import { useState } from "react";
import useInput from "form/Hooks/user-input";
import Textarea from "form/Inputs/Textarea";
import { useTranslation } from "react-i18next";
import inputLabels from "Constant/Inputs";
const Form = () => {
  const { t, i18n } = useTranslation();

  const [isFormNotValid, setIsFormNotValid] = useState(false);
  const [formIsSuccess, setFormIsSuccess] = useState(false);
  const [formIsFaield, setFormIsFailed] = useState(false);

  const {
    value: fullNameInput,
    isValid: fullNameIsValid,
    HasError: fullNameHasError,
    inputChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHandler,
    inputFocusHandler: fullNameFocusHanlder,
    isFocus: fullNameIsFocus,
    reset: fullnameReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    HasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    inputFocusHandler: phoneNumberFocusHanlder,
    isFocus: phoneNumberIsFocus,
    reset: phoneNumberRest,
  } = useInput((value) => value.trim() !== "");

  const {
    value: companyNameInput,
    isValid: companyNameIsValid,
    HasError: companyNameHasError,
    inputChangeHandler: companyNameChangeHandler,
    inputBlurHandler: companyNameBlurHandler,
    inputFocusHandler: companyNameFocusHanlder,
    isFocus: companyNameIsFocus,
    reset: companyNameReset,
  } = useInput((value) => value.trim() !== "");
  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    inputFocusHandler: emailFocusHanlder,
    isFocus: emailIsFocus,
    reset: emailReset,
  } = useInput((value) => value.trim() !== "");
  const {
    value: messageInput,
    isValid: messageIsValid,
    HasError: messageHasError,
    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    inputFocusHandler: messageFocusHanlder,
    isFocus: messageIsFocus,
    reset: messageReset,
  } = useInput((value) => value.trim() !== "");

  const clearErrors = () => {
    setIsFormNotValid(false);
    setFormIsFailed(false);
    setFormIsSuccess(false);
  };

  const resetInputs = () => {
    fullnameReset();
    emailReset();
    companyNameReset();
    messageReset();
    phoneNumberRest();
  };

  const formIsValid =
    fullNameIsValid &&
    emailIsValid &&
    phoneNumberIsValid &&
    messageIsValid &&
    companyNameInput;

  const formSubmit = async (e) => {
    e.preventDefault();
    clearErrors();
    if (!formIsValid) {
      setIsFormNotValid(true);
      return;
    }

    try {
      const response = await fetch("https://networksworld.sa/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: fullNameInput,
          phone_number: phoneNumberInput,
          email: emailInput,
          company_name: companyNameInput,
          message: messageInput,
        }),
      });

      if (!response.ok) {
        setFormIsFailed(true);
        throw new Error("Failed to submit form");
      } else {
        const data = await response.json();

        if (data.success) {
          resetInputs();
          setFormIsSuccess(true);

          // Set a timer to deactivate the success state after 2 seconds
          setTimeout(() => {
            setFormIsSuccess(false);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      setFormIsFailed(true);
    }
  };

  return (
    <section className="py-primary bg-gray-200">
      <Container>
        <div className="flex items-center justify-center">
          <div>
            {" "}
            <h1
              className={`${
                i18n.language === "ar" ? "font-almarai" : "font-roboto"
              } text-4xl uppercase text-center`}
            >
              {inputLabels[i18n.language].title}
            </h1>
            {isFormNotValid && (
              <p className="text-center text-red-400">
                {inputLabels[i18n.language].formIsNotValid}
              </p>
            )}
            {formIsFaield && (
              <p className="text-center text-red-400">
                {inputLabels[i18n.language].formIsFailed}
              </p>
            )}
            {formIsSuccess && (
              <p className="text-center text-green">
                {inputLabels[i18n.language].formIsSuccess}
              </p>
            )}
          </div>
        </div>

        <form
          className={`flex flex-col gap-y-6 mt-14 ${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          } `}
        >
          <div className="flex flex-col md:flex-row items-center gap-6">
            <Input
              type={"text"}
              label={inputLabels[i18n.language].Full_Name}
              name={"fullName"}
              id={"fullName"}
              value={fullNameInput}
              onChange={(event) => {
                fullNameChangeHandler(event);
                clearErrors();
              }}
              onBlur={fullNameBlurHandler}
              onFocus={fullNameFocusHanlder}
              isFocus={fullNameFocusHanlder}
              hasError={fullNameHasError}
              errorMessage={"Full Name is required"}
            />

            <Input
              type={"text"}
              label={inputLabels[i18n.language].Company}
              name={"companyName"}
              id={"companyName"}
              value={companyNameInput}
              onChange={(event) => {
                companyNameChangeHandler(event);
                clearErrors();
              }}
              onBlur={companyNameBlurHandler}
              onFocus={companyNameFocusHanlder}
              isFocus={companyNameIsFocus}
              hasError={companyNameHasError}
              errorMessage={"company name is required"}
            />
            {/* <Input
              type={"text"}
              label={inputLabels[i18n.language].Last_Name}
              name={"lastName"}
              id={"lastName"}
              value={lastNameInput}
              onChange={(event) => {
                lastNameChangeHandler(event);
              }}
              onBlur={lastNameBlurHandler}
              onFocus={lastNameFocusHanlder}
              isFocus={lastNameIsFocus}
              hasError={lastNameHasError}
              errorMessage={"Last Name is required"}
            /> */}
          </div>

          <div className="flex flex-col md:flex-row items-center gap-6">
            <Input
              type={"number"}
              label={inputLabels[i18n.language].Phone_Number}
              name={"phoneNumber"}
              id={"phoneNumber"}
              value={phoneNumberInput}
              onChange={(event) => {
                phoneNumberChangeHandler(event);
                clearErrors();
              }}
              onBlur={phoneNumberBlurHandler}
              onFocus={phoneNumberFocusHanlder}
              isFocus={phoneNumberIsFocus}
              hasError={phoneNumberHasError}
              errorMessage={"Phone number is required"}
            />
            <Input
              type={"email"}
              label={inputLabels[i18n.language].Work_Email}
              name={" email"}
              id={"email"}
              value={emailInput}
              onChange={(event) => {
                emailChangeHandler(event);
                clearErrors();
              }}
              onBlur={emailBlurHandler}
              onFocus={emailFocusHanlder}
              isFocus={emailIsFocus}
              hasError={emailHasError}
              errorMessage={"email is required"}
            />
          </div>

          <Textarea
            type={"text"}
            label={inputLabels[i18n.language].Message}
            name={"your-message"}
            id={"your-message"}
            value={messageInput}
            onChange={(event) => {
              messageChangeHandler(event);
              clearErrors();
            }}
            onBlur={messageBlurHandler}
            onFocus={messageFocusHanlder}
            isFocus={messageIsFocus}
            hasError={messageHasError}
            errorMessage={"Message  is required"}
          />

          <button
            onClick={formSubmit}
            className="bg-green px-8 py-3 uppercase text-sm font-roboto font-[500] rounded-2xl w-max flex items-center justify-center mx-auto mt-4"
          >
            {inputLabels[i18n.language].Submit}
          </button>
        </form>
      </Container>
    </section>
  );
};

export default Form;
