import { useTranslation } from "react-i18next";
// inner components
import AboutHero from "./Sections/AboutHero/AboutHero";
import AboutContent from "./Sections/AboutContent/AboutContent";
import OurValues from "./Sections/Values/OurValues";
import ParentCompany from "./Sections/Parent/ParentCompany";
import PartnerSlider from "Pages/Home/Sections/Partners/Partner";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const About = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("about-us");
  const aboutUsData = data?.about.about;

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const heroData = aboutUsData[i18n.language]?.hero;
    const aboutData = aboutUsData[i18n.language]?.aboutus;
    const valuesData = aboutUsData[i18n.language]?.values;
    const parentData = aboutUsData[i18n.language]?.ourParents;
    const partnersData = aboutUsData[i18n.language]?.ourPartnersLogo;

    return (
      <main>
        <AboutHero data={heroData} />
        <AboutContent data={aboutData} />
        <OurValues data={valuesData} />
        <ParentCompany data={parentData} />
        <PartnerSlider data={partnersData} />x
      </main>
    );
  }

  return null;
};

export default About;
