import { useState, useEffect } from "react";
import Container from "Components/Container/Container";
// inner components
import Logo from "./Components/Logo/Logo";
import Contact from "./Components/Contact/Contact";
import Navigation from "./Components/Navigation/Navigation";
import MobileNavigation from "./Components/Navigation/MobileNavigation";
// icons
import { List, X } from "@phosphor-icons/react";
const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const toggleMobileMenu = () => {
    setShowMobileMenu((cur) => !cur);
  };

  useEffect(() => {
    const checkScroll = () => {
      setIsScroll(window.scrollY > 50);
    };

    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMobileMenu]);

  return (
    <header
      className={`transition-all ease-in duration-300 z-[1000] ${
        isScroll
          ? "sticky top-0 left-0 w-full bg-white h-auto shadow-xl"
          : "absolute h-[15vh]"
      } top-0 left-0 w-full  `}
    >
      <Container>
        <div className="flex items-center justify-between border-b border-white">
          <Logo isScroll={isScroll} />
          <div className="hidden xl:flex flex-col gap-y-4 ">
            {!isScroll && <Contact />}
            <Navigation isScroll={isScroll} />
          </div>

          <button onClick={toggleMobileMenu} className="xl:hidden">
            {showMobileMenu ? (
              <X size={32} color="white" />
            ) : (
              <List size={30} color="white" />
            )}
          </button>

          <MobileNavigation
            onToggle={toggleMobileMenu}
            active={showMobileMenu}
          />
        </div>
      </Container>
    </header>
  );
};

export default Header;
