import React from "react";
import Container from "Components/Container/Container";
import { Phone, EnvelopeSimple } from "@phosphor-icons/react";
import Map from "./Map";
import { useTranslation } from "react-i18next";
import htmlToText from "Helpers/htmlToText";
const Header = ({ data, infoData }) => {
  const { t, i18n } = useTranslation();
  const iconSize = 18;

  return (
    <section
      className="background-class bg-blue"
      style={{
        backgroundImage: data && data?.image ? `url(${data.image})` : "",
      }}
    >
      <Container className="h-full pt-44 pb-24 ">
        <div className="h-full flex flex-col justify-center text-white ">
          <h1
            className={`capitalize ${
              i18n.language === "ar" ? "font-almarai" : "font-orbi"
            }  text-3xl mb-8`}
          >
            {htmlToText(data.title)}
          </h1>

          {infoData.map(
            ({ address, street, phone, email, location }, index) => (
              <div key={index}>
                <div
                  className={`${
                    i18n.language === "ar" ? "font-almarai" : "font-roboto"
                  } flex flex-col gap-y-5`}
                >
                  <p className="text-2xl font-[300] uppercase">{address}</p>
                  <p className="text-lg font-[300] w-1/2">{street}</p>
                  {phone && (
                    <span className="flex items-center gap-x-2">
                      <span className="rounded-full p-1 bg-green flex items-center justify-center w-max">
                        <Phone weight="fill" size={iconSize} color="#8a68ad" />
                      </span>
                      <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
                        {" "}
                        {phone}
                      </a>
                    </span>
                  )}
                  {email && (
                    <span className="flex items-center gap-x-2">
                      <span className="rounded-full p-1 bg-white flex items-center justify-center w-max">
                        <EnvelopeSimple size={iconSize} color="#008adc" />
                      </span>
                      <a
                        href={`mailto:${email}`}
                        target="_blank"
                        className="hover:underline"
                        rel="noreferrer"
                      >
                        {email}
                      </a>
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    i18n.language === "ar" ? "font-almarai" : "font-roboto"
                  } md:w-3/4 lg:w-1/2 mt-8`}
                >
                  <Map data={location} />
                </div>
              </div>
            )
          )}
        </div>
      </Container>
    </section>
  );
};

export default Header;
