import Container from "Components/Container/Container";
import { useTranslation } from "react-i18next";
import fixImageUrl from "Helpers/FixImageUrl";
import htmlToText from "Helpers/htmlToText";
const AboutHero = ({ data }) => {
  const { t, i18n } = useTranslation();
  const imageUrl = fixImageUrl(data?.image);

  return (
    <section
      className="h-[100vh] background-class"
      style={{
        backgroundImage: data && data?.image ? `url(${imageUrl})` : "",
      }}
    >
      <Container className="h-full">
        <div className="h-full flex flex-col justify-center lg:w-[60%]">
          <p
            className={`${
              i18n.language === "ar" ? "font-almarai" : "font-orbi"
            } font-bold uppercase text-white text-3xl mb-4`}
          >
            {t("description", {
              defaultValue: data.title,
            })}
          </p>
          <h1
            className={`${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            } text-xl text-white font-[400]  mb-3`}
          >
            {t("title", { defaultValue: data.subtitle })}
          </h1>
          <p
            className={`${
              i18n.language === "ar" ? "font-almarai" : "font-roboto"
            } leading-7 text-white font-[300]`}
          >
            {/* {data.text} */}

            {htmlToText(data?.text)}
          </p>
        </div>
      </Container>
    </section>
  );
};

export default AboutHero;
