import Container from "Components/Container/Container";
import React from "react";
import { useTranslation } from "react-i18next";
import image from "Asset/about-us-content.jpg";
import htmlToText from "Helpers/htmlToText";

const AboutContent = ({ data }) => {
  const { t, i18n } = useTranslation();

  const paragraphs = data.split("</p>");

  return (
    <section
      className="background-class  py-secondary lg:py-primary"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <Container className={"h-full py-14"}>
        <div
          className={`h-full ${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          } flex flex-col justify-center items-center`}
        >
          {paragraphs.map((paragraph, index) => (
            <p key={index} className="font-[400] leading-8 mb-4">
              {htmlToText(paragraph)}
            </p>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AboutContent;
