import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

// inner components
import DetailedServicesHero from "./Sections/DetailedServicesHero";
import DeatiledServicesBoxes from "./Sections/DeatiledServicesBoxes";

const DetailedServices = () => {
  const { slug } = useParams();
  const { i18n } = useTranslation();

  const { data, loading, error } = useFetch(`services/${slug}`);

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const servicesDetailedData = data?.servicedata[i18n.language];
    const heroData = servicesDetailedData?.hero;
    const servicesData = heroData?.list;
    return (
      <main>
        <DetailedServicesHero data={heroData} />
        <DeatiledServicesBoxes servicesData={servicesData} />
      </main>
    );
  }
};

export default DetailedServices;
