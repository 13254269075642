import { useTranslation } from "react-i18next";
import Container from "Components/Container/Container";

import PartnerSlider from "./slider/PartnerSlider";
import htmlToText from "Helpers/htmlToText";
const Partner = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <main className="my-secondary lg:my-primary">
      <Container>
        <div
          className={`mb-innner ${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          }`}
        >
          <h4 className="text-lg text-blue font-[500] capitalize">
            {t("title", {
              defaultValue: data?.title,
            })}
          </h4>
          <h1 className="text-black capitalize text-3xl md:text-4xl font-[500] mt-1 mb-2">
            {htmlToText(data?.subtitle)}
          </h1>
        </div>

        <PartnerSlider data={data?.logoData} />
      </Container>
    </main>
  );
};

export default Partner;
