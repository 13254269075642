import React from "react";
import { Link } from "react-router-dom";

const LinksCol = ({ links, style }) => {
  return (
    <ul className="flex flex-col gap-y-3 md:col-span-1 lg:col-span-1">
      {links?.map(({ title, url }, index) => (
        <Link key={index} to={url} className={`${style} hover:underline`}>
          {title}
        </Link>
      ))}
    </ul>
  );
};

export default LinksCol;
