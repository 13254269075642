import React from "react";
import Container from "Components/Container/Container";
import { useTranslation } from "react-i18next";
import fixImageUrl from "Helpers/FixImageUrl";
import htmlToText from "Helpers/htmlToText";
const OurValues = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <section
      className="background-class py-secondary lg:py-primary"
      style={{
        backgroundImage:
          data && data?.image ? `url(${fixImageUrl(data.image)})` : "",
      }}
    >
      <Container className={"h-full"}>
        <div
          className={`h-full flex flex-col justify-center gap-y-14 lg:w-[50%] ${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          }`}
        >
          <div>
            <h2 className="text-purple font-[500] mb-1 text-3xl capitalize">
              {t("description", {
                defaultValue: data.title,
              })}
            </h2>
            <p className="font-[400]">{htmlToText(data.text)}</p>
          </div>

          <div>
            <h4 className="text-purple font-[500] mb-1 text-3xl capitalize">
              {t("text", {
                defaultValue: data.list.title,
              })}
            </h4>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-14 text-lg  mt-2 sm:mt-4">
              {data.list.listData.map(({ title, image }, index) => (
                <span key={index}>
                  <img
                    src={image}
                    alt={title}
                    className="w-12 sm:w-14 h-12 sm:h-14"
                  />
                  <li key={index} className="font-[400]">
                    {t("text", {
                      defaultValue: title,
                    })}
                  </li>
                </span>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OurValues;
