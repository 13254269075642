import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FacebookLogo, InstagramLogo, X } from "@phosphor-icons/react";
import footerContent from "Constant/Footer";
import Spinner from "Components/RequestHandler/Spinner";
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
const ContactCol = ({ style }) => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("contact-us");
  const iconSize = 22;
  const iconBox =
    "flex items-center justify-center p-1 bg-green rounded-full w-max";

  if (loading)
    return (
      <div>
        <Spinner />
      </div>
    );
  else if (error)
    return (
      <p>
        <p>
          Failed to <br /> retrieve data
        </p>
      </p>
    );
  else if (data) {
    const infoData = data?.contactus[i18n.language]?.list;
    return (
      <ul className="flex flex-col lg:col-span-1 ">
        <Link to={"/contact-us"} className={`${style} hover:underline`}>
          {i18n.language === "ar" ? "اتصل بنا" : "Contact us"}
        </Link>

        {infoData.map(({ address, street, phone, email }, index) => (
          <div className="mt-4">
            <p className="text-sm mb-4 font-bold">{address}</p>
            <p className="text-sm">{street}</p>
            <div className="mt-4 mb-6">
              {phone && (
                <span className="flex items-center gap-x-1">
                  <p className="text-sm font-[500]">mob:</p>
                  <a
                    className="hover:underline"
                    href={`tel:${phone}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    {phone}
                  </a>
                </span>
              )}

              <a
                className="hover:underline"
                href={`mailto:${email}`}
                target="_blank"
                rel="noreferrer"
              >
                {email}
              </a>
            </div>

            {/* <div>
            <p className="uppercase text-sm font-[500] mb-2">
              {footerContent[i18n.language]?.contactInfo?.socialMedia}
            </p>
            <div className="flex items-center gap-x-4">
              <a className={iconBox} href="">
                <FacebookLogo color="white" size={iconSize} />
              </a>
              <a className={iconBox} href="">
                <InstagramLogo color="white" size={iconSize} />
              </a>

              <a className={iconBox} href="">
                <X color="white" size={iconSize} />
              </a>
            </div>
          </div> */}
          </div>
        ))}
      </ul>
    );
  }
};

export default ContactCol;
