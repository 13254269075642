import React from "react";

const LandingVendor = ({ vendors }) => {
  return (
    <div className="hidden md:flex">
      <div className="flex items-center gap-8 flex-wrap">
        {vendors.map(({ title, image }, vnIndex) => (
          <div
            key={vnIndex}
            className="flex flex-col items-center justify-center rounded-2xl"
          >
            <div className="border border-gray-500 w-[10rem] h-[6rem] p-2  rounded-2xl bg-white flex items-center center justify-center overflow-hidden">
              <img
                src={image}
                alt=""
                className=" rounded-2xl w-full h-full object-contain"
              />
            </div>
            <p className="font-roboto text-sm font-[600] mt-2"> {title}</p>
          </div>
        ))}
        {/* {vendors.map(({ title, logo }, vnIndex) => (
          <div
            key={vnIndex}
            className="flex flex-col items-center justify-center rounded-2xl"
          >
            <div className="border border-gray-500 w-[10rem] h-[6rem] p-2  rounded-2xl bg-white flex items-center center justify-center overflow-hidden">
              <img
                src={logo}
                alt=""
                className=" rounded-2xl w-full h-full object-contain"
              />
            </div>
            <p className="font-roboto text-sm font-[600] mt-2"> {title}</p>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default LandingVendor;
