import { useTranslation } from "react-i18next";

import Header from "./Sections/Header";
import Form from "./Sections/Form";

import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const ContactUs = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("contact-us");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const heroData = data?.contactus[i18n.language]?.hero;
    const infoData = data?.contactus[i18n.language]?.list;
    return (
      <main>
        <Header data={heroData} infoData={infoData} />
        <Form />
      </main>
    );
  }
};

export default ContactUs;
