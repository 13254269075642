import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./Services.css";
import { Link } from "react-router-dom";
import buttonContent from "Constant/Button";
import fixImageUrl from "Helpers/FixImageUrl";
import htmlToText from "Helpers/htmlToText";

const Services = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [swiperKey, setSwiperKey] = useState(0);

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [i18n.language]);

  const headerData = {
    ar: {
      title: "خدماتنا",
      text: "متخصصون في عالم التحول الرقمي",
    },
    en: {
      title: "our services",
      text: "specialized in the world of digital transformation",
    },
  };

  const breakpoints = {
    992: {
      slidesPerView: 1.15,
      spaceBetween: 30,
    },
  };

  return (
    <section className="my-secondary lg:my-primary">
      <div
        className={
          i18n.language === "ar"
            ? "semi-container-right"
            : "semi-container-left"
        }
      >
        <div
          className={`${
            i18n.language === "ar" ? "font-almarai" : "font-roboto"
          } lg:w-1/2 mb-innner`}
        >
          <div>
            <p className="text-green capitalize">
              {" "}
              {headerData[i18n.language].title}
            </p>
            <h1 className="text-purple font-[500] uppercase text-2xl sm:text-3xl md:text-4xl ">
              {headerData[i18n.language].text}
            </h1>
          </div>
        </div>
        <Swiper
          key={swiperKey}
          breakpoints={breakpoints}
          spaceBetween={30}
          pagination={{
            el: ".my-dots-container",
            clickable: true,
            type: "bullets",
          }}
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
          modules={[Pagination]}
          className="services-swiper "
          style={{ display: "flex", alignItems: "stretch" }}
          loop={true}
        >
          {data.servicesList.map((item, index) => (
            <SwiperSlide key={index} className="rounded-2xl relative ">
              <div
                className="background-class flex flex-col  h-full justify-center lg:justify-start rounded-2xl px-4 py-20 md:px-10 md:py-20 text-white sm:h-[500px] lg:h-[600px] xl:h-[500px] relative z-[1] "
                style={{ backgroundImage: `url(${fixImageUrl(item.image)})` }}
              >
                <div
                  className={` ${
                    i18n.language === "ar" ? "font-almarai" : "font-roboto"
                  }`}
                >
                  <h1 className=" capitalize font-extrabold text-2xl md:text-3xl md:w-3/4 lg:w-1/2    ">
                    {t("title", { defaultValue: item.title })}
                  </h1>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 grid-rows-3 gap-3 list-disc list-inside  capitalize text-lg font-[500] my-6 lg:my-8 services-list lg:w-[63%] xxl:w-1/2  ">
                    {item.list.map(({ title }, index) => (
                      <li key={index}>{title}</li>
                    ))}
                  </ul>
                </div>

                <div>
                  <Link
                    to={`services/${item.slug}`}
                    className={`bg-green px-4 py-3 uppercase text-sm  font-[500] rounded-2xl ${
                      i18n.language === "ar" ? "font-almarai" : "font-roboto"
                    }`}
                  >
                    {buttonContent[i18n.language].readMore}
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}

          <div className="w-full flex items-center justify-between gap-x-6 mt-10">
            <div className="w-[60%] sm:w-[75%] md:w-[80%] lg:w-[85%] h-[3px] bg-purple"></div>

            <div className="w-[40%] sm:w-[25%] md:w-[20%] lg:w-[15%] flex gap-x-2">
              <div class="my-dots-container"></div>
            </div>
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default Services;
