import { useState } from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import headerData from "Constant/Header";
import { Link, useLocation } from "react-router-dom";
import logo from "Asset/Header/NWC-logo.png";
import { EnvelopeSimple, CaretDown } from "@phosphor-icons/react";

const MobileNavigation = ({ active, onToggle }) => {
  const { i18n } = useTranslation();
  const links = headerData();
  const [activeIndex, setActiveIndex] = useState(null);
  const iconSTyle = {
    color: "#008adc",
    size: 24,
  };
  return (
    <div
      className={`${
        active
          ? `translate-x-[0%] scale-[1] opacity-100`
          : `scale-[0.8] opacity-50 ${
              i18n.language === "ar"
                ? "translate-x-[100%]"
                : "translate-x-[-100%]"
            }`
      } fixed  ${
        i18n.language === "ar" ? "right-0" : "left-0"
      } top-0 h-[100dvh] w-[70vw] sm:w-[45vw] lg:w-[35vw] bg-purple text-white transition ease-in duration-300 z-[1000] p-4 xl:hidden flex flex-col gap-y-6 justify-center`}
    >
      <div className="flex items-center justify-between">
        <Link to={"/"}>
          <img className="w-3/4  object-contain" src={logo} alt="" />
        </Link>
      </div>
      <ul className=" flex flex-col justify-center h-full gap-y-6 ">
        {links?.[i18n.language]?.map(
          ({ text, link, mega, serviceData }, index) => (
            <li key={index} className="border-b-2 border-blue">
              <Link
                onClick={onToggle}
                to={link}
                className={`font-roboto uppercase  font-[500] tracking-wider transition ease-in duration-300 text-lg sm:text-xl flex items-center justify-between `}
              >
                {text}
              </Link>

              {mega && activeIndex === index && (
                <ul className="ml-2 sm:ml-4 flex flex-col gap-y-2 list-disc list-inside my-3">
                  {serviceData.map(({ slug, title }, innerIndex) => (
                    <li>
                      <Link onClick={onToggle} to={slug}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )
        )}
        <LanguageSwitcher isMobile={true} />
      </ul>

      <div className="flex items-center justify-center gap-x-2 bg-white rounded-md py-3 font-semibold ">
        <EnvelopeSimple
          size={iconSTyle.size}
          color={iconSTyle.color}
          weight="bold"
        />
        <a href="mailto:info@networksworld.sa" className="text-black">
          info@networksworld.sa
        </a>
      </div>
    </div>
  );
};

export default MobileNavigation;
