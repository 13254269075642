import React from "react";
import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
} from "@phosphor-icons/react";
const Contact = () => {
  const iconSTyle = {
    color: "#008adc",
    size: 18,
  };
  return (
    <div className="font-roboto text-white flex items-center justify-end gap-x-2 ">
      <a href="" className="font-[500]">
        info@networksworld.sa
      </a>
      <div className="flex items-center gap-x-2">
        <a
          href="mailto:info@networksworld.sa"
          className="bg-white p-1 rounded-full flex items-center justify-center"
        >
          <EnvelopeSimple
            size={iconSTyle.size}
            color={iconSTyle.color}
            weight="bold"
          />
        </a>
        {/* <a
          href=""
          className="bg-white p-1 rounded-full flex items-center justify-center"
        >
          <FacebookLogo
            size={iconSTyle.size}
            color={iconSTyle.color}
            weight="bold"
          />
        </a>
        <a
          href=""
          className="bg-white p-1 rounded-full flex items-center justify-center"
        >
          <InstagramLogo
            size={iconSTyle.size}
            color={iconSTyle.color}
            weight="bold"
          />
        </a> */}
      </div>
    </div>
  );
};

export default Contact;
