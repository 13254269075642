import { useTranslation } from "react-i18next";
// fetching
import useFetch from "Hooks/useFetch";
import Spinner from "Components/RequestHandler/Spinner";

import { Link } from "react-router-dom";

const ServicesCol = ({ style }) => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useFetch("services");

  if (loading)
    return (
      <div>
        <Spinner />
      </div>
    );
  else if (error)
    return (
      <p>
        Failed to <br /> retrieve data
      </p>
    );

  if (data) {
    const serviceData = data?.servicedata[i18n.language].servicesData;

    return (
      <ul className="flex flex-col md:col-span-3 ">
        <Link to={"/services"} className={`${style} hover:underline`}>
          {i18n.language === "ar" ? "خدماتنا" : "Services"}
        </Link>

        <div className="grid gap-y-3 grid-cols-1 md:grid-cols-4 ">
          {serviceData?.map(({ title, list, slug }, index) => (
            <span
              key={index}
              className="text-sm col-span-2 flex flex-col gap-y-2 mt-4"
            >
              <Link
                to={`services/${slug}`}
                className="font-[600] text-base hover:underline"
              >
                {title}
              </Link>
              {list.map(({ title }, itemIndex) => (
                <li key={itemIndex}>{title}</li>
              ))}
            </span>
          ))}
        </div>
      </ul>
    );
  }
};

export default ServicesCol;
