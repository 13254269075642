import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import i18n from "./i18n";

const root = createRoot(document.getElementById("root"));

const LanguageProvider = () => {
  const [lang, setLang] = useState(i18n.language);
  const storedLanguage = localStorage.getItem("selectedLanguage");

  const handleLanguageChange = async (language) => {
    await i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
  };

  const updateDirection = (language) => {
    document.documentElement.dir = language === "ar" ? "rtl" : "ltr";
  };

  useEffect(() => {
    if (storedLanguage) {
      handleLanguageChange(storedLanguage);
      setLang(storedLanguage);
    }
  }, [storedLanguage]);

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
      updateDirection(language);
    });

    return () => {
      i18n.off("languageChanged", setLang);
    };
  }, []);

  useEffect(() => {
    updateDirection(lang);
  }, [lang]);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
          <App />
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
};

root.render(<LanguageProvider />);
